import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { isError } from "../storeHelpers"

import { BASE_URI } from "../../constants/auth"

import { IClientReportDataFilter, IClientState } from "./clientTypes"
import {
  getClientReportData,
  getClientReportLink,
} from "./clientAsync"


const initialState: IClientState = {
  reportsData: null,
  reportUrl: null,
  isLoading: false,
  count: 0,
  error: "",
  status: 0,
  filters: null
}

export const clientSlice = createSlice({
  name: "client",
  initialState,
  reducers: {
    resetUrl(state) {
      state.reportUrl = null
    },
    setFilterData(state, action: PayloadAction<IClientReportDataFilter> ){
      state.filters = action.payload
    },
    resetFilterData(state){
      state.filters = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getClientReportData.pending, (state) => {
        state.isLoading = false
        state.reportsData = null
        state.count = 0
      })
      .addCase(getClientReportData.fulfilled, (state, action) => {
        state.isLoading = false
        state.reportsData = action.payload.data
        state.count = action.payload.count
      })
      .addCase(getClientReportLink.pending, (state) => {
        state.reportUrl = null

      })
      .addCase(getClientReportLink.fulfilled, (state, action) => {
        state.reportUrl = BASE_URI + "/" + action.payload.msg
      })
      .addMatcher(isError, (state, action) => {
        state.error = action.payload
        state.status = action.payload
        state.isLoading = false
      })
  },
})

export const {
  resetUrl,
  setFilterData,
  resetFilterData
} = clientSlice.actions

export default clientSlice.reducer
